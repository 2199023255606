/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    ul: "ul",
    li: "li",
    h3: "h3",
    pre: "pre",
    code: "code",
    h4: "h4",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "This is a general note catch for server setup, configuration and security best practices."), "\n", React.createElement(_components.p, null, "Server configuration has a fair over lap with ssh and general Linux commands. Because of this overlap some content may be referenced only."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/notes/ssh-catch"
  }, "ssh notes")), "\n", React.createElement(_components.h2, null, "Configring A New Server Checklist"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "[ ] add new admin"), "\n", React.createElement(_components.li, null, "[ ] disable root admin"), "\n", React.createElement(_components.li, null, "[ ] ssh"), "\n"), "\n", React.createElement(_components.h3, null, "Security Hardening"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "[ ] disable password login"), "\n", React.createElement(_components.li, null, "[ ] change ssh port"), "\n"), "\n", React.createElement(_components.h2, null, "Access Remote Server"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "ssh -v user@IP-Address\n")), "\n", React.createElement(_components.h2, null, "Adding Users, Passwords and Groups via ClI"), "\n", React.createElement(_components.h3, null, "Add User"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "useradd <new-username>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "id <new-username>\n")), "\n", React.createElement(_components.h4, null, "Add Password to User"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "sudo passwd <new-username>\n# props for password and password confirmation\n")), "\n", React.createElement(_components.h4, null, "Add User to Group"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "sudo usermod -aG <group> <user-name>\n")), "\n", React.createElement(_components.h4, null, "Add User to Group"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "sudo usermod -d  <user-name> <group>\n")), "\n", React.createElement(_components.h4, null, "Delete User Group"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "sudo groupdel <group>\n")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "🚨 security, when configuring a new server setup ssh first add a new admin and disable the root user and password login"), "\n"), "\n", React.createElement(_components.h3, null, "Role Naming Convention"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "💡 The naming conventions are just general options. Also note that by using one of these hackers have a known vector to brut force a server."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "<auth-level>.<firstname>.<surname>@<domain>")), "\n", React.createElement(_components.h4, null, "Option One"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt"
  }, "EA-first.last # Enterprise Admin.\n\nDA-first.last # Domain Admin.\n\nSA-first.last # Server Admin.\n\nWA-first.last # Workstation Admin.\n\nFirst.last # user level.\n")), "\n", React.createElement(_components.h4, null, "Option Two"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt"
  }, "Tier One (Domain Admin): t1.username\n\nTier Two (Server-Only Admin): t2.username\n\nTier Three (Workstation-Only Admin): t3.username\n\n(standard daily-driver): username\n")), "\n", React.createElement(_components.h2, null, "Reference"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.vultr.com/docs/best-practices-for-ssh-on-a-production-cloud-server/"
  }, "Best Practices: production cloud server - vultr")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://linuxize.com/post/how-to-create-users-in-linux-using-the-useradd-command/"
  }, "How to create users in Linux cli - linuxize")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://systemd.io/USER_NAMES/"
  }, "system usersname - systemd.io")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.linuxfordevices.com/tutorials/linux/enable-disable-root-login-in-linux"
  }, "Enable or Disable root login linux - linuxfordevices.com")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
